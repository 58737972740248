import React, { useState } from 'react'
import "./seccion.css";
import icon1 from "../../images/iconos/imaginacion2.png";
import icon2 from "../../images/iconos/bosquejo2.png";
import icon3 from "../../images/iconos/arquitecto3.png";
import { NAVBAR } from '../navbar/navbar';
import { FOOTERC } from '../footer/footer';

export const SECCION = (props) => {
  
  const [ingles, setingles]=useState(
    localStorage.getItem("ingles_curiel_web") === null
      ? false
      : localStorage.getItem("ingles_curiel_web") === "true"
      ? true
      : false
  );


  return (
    <>
      <NAVBAR op={2} ingles={ingles} setingles={setingles}></NAVBAR>
      <div className='seccion-container' id='seccion-container'>
      <div className='capa'></div>
      <div className='titulo-text'>
        <p classname='p-services-prof'>
          {
            ingles===true?
            "Professional Services in Architecture, Interior Design, and Construction":
            "Servicios profesionales de Arquitectura, Interiorismo y Construcción"
          }
          </p>
      </div>
        
      <div className='seccion-cont-grid'>
        <div className='secg1 seccinfo'>
            <img src={icon1} alt=''></img>
            <h2>{ingles===true?"We imagine":"Imaginamos"}</h2>
            {
              ingles===true?
              <p>
                Imagining is a daily process; we seek inspiration in the most ordinary or exceptional day-to-day occurrences.
              </p>:
               <p>
                Imaginar es un proceso diario, buscamos inspiración en lo más cotidiano ó excepcional
                del día a día.
              </p> 
            }
        </div>
        <div className='secg2 seccinfo'>
          <img src={icon2} alt=''></img>
          <h2>{ingles===true?"We design":"Diseñamos"}</h2>
          {
            ingles===true?
            <p>
                Concepts, ideas, drawings, madness, details, and life are landed and captured in images and paper.
            </p>:
            <p>
                Conceptos, ideas, dibujos, locuras, detalles, y vida son aterrizados y plasmados en imagenes y papel.
            </p>
          }
        </div>
        <div className='secg3 seccinfo'>
          <img src={icon3} alt=''></img>
          <h2>{ingles===true?"We build":"Construimos"}</h2>
          {
            ingles===true?
            <p>
              Correct execution and the management of supplies and budget result in solid, functional, and beautiful construction.
            </p>:
            <p>
              La correcta ejecución y el manejo de insumos y presupuestos dan como resultado una construcción 
              sólida, funcional y bella.
            </p>
          }
          
        </div>
      </div>
    </div>
    <FOOTERC ingles={ingles}></FOOTERC>
    </>
    
  )
}
