import React, { useState } from 'react'
import { NAVBAR } from '../navbar/navbar'
import { FOOTERC } from '../footer/footer'
import "./team.css";
import b1 from "../../images/Nosotros/Direccion (1).jpg";
import b2 from "../../images/Nosotros/Direccion (2).jpg";
import b3 from "../../images/Nosotros/Direccion (3).jpg";
import data from "./team.json";


export const TEAM = () => {
  
  const [ingles, setingles]=useState(
    localStorage.getItem("ingles_curiel_web") === null
      ? false
      : localStorage.getItem("ingles_curiel_web") === "true"
      ? true
      : false
  );

  return (
    <>
    <NAVBAR ingles={ingles} setingles={setingles}></NAVBAR>
    <div className='team-container'>
      <div className='team-galery'>
        <div className='curiel-boss'>
          <div className='boss1 bosscarta'>
              <div className='foto'>
                <img src={b1} alt=''></img>
              </div>
              <div className='puesto'>
                <p className='p-name'>David Curiel</p>
                <p className='p-puesto'>{ingles===true?"Director of architecture and desing":"Director de Arquitectura y Diseño"}</p>                
              </div>
          </div>
          <div className='boss2 bosscarta'>
              <div className='foto'>
                <img src={b2} alt=''></img>
              </div>
              <div className='puesto'>
                <p className='p-name'>Gabriel Curiel</p>
                <p className='p-puesto'>{ingles===true?"Financial director":"Director Financiero"}</p>      
              </div>
          </div>
          <div className='boss3 bosscarta'>
              <div className='foto'>
                <img src={b3} alt=''></img>
              </div>
              <div className='puesto'>
                <p className='p-name'>Gabriel Curiel F</p>
                <p className='p-puesto'>{ingles===true?"Director of construction":"Director de Construcción"}</p>       
              </div>
          </div>
        </div>
        <div className='team'>
          <h2 className='team-arq-dis'>{ingles===true?"Architecture and design team":"Equipo de Arquitectura y Diseño"}</h2>     
          <div className='grid-team'>
            {
              data?.map((item, index)=>{
                if(index < 16){
                  return (
                    <div className='bosscarta2'>
                      <div className='puesto'>
                        <p className='p-name-normal'>{item.name}</p>
                      </div>
                    </div>
                  )
                }
              })
            }
          </div>
          <div className='grid-team-2'>
            {
              data?.map((item, index)=>{
                if(index > 15){
                  return (
                    <div className='bosscarta2'>
                      <div className='puesto'>
                        <p className='p-name-normal'>{item.name}</p>
                      </div>
                    </div>
                  )
                }
              })
            }
          </div>
        </div>
      </div>
    </div>
    <FOOTERC ingles={ingles}></FOOTERC>
    </>
    
  )
}
