import React from 'react'
import "./footer.css";
import icon from "../../images/Logo_curiel_black.png";
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';

export const FOOTERC = (props) => {
  return (
    <div className='footer-container'>
        <div className='column-footer'>
            <div className='col2'>
                <div className='cont-redes'>
                    <div className='cont-iconos'>
                        <div className='iconos' onClick={()=>window.open("https://www.facebook.com/curielarquitectos", "_blank")}>
                            <FacebookIcon></FacebookIcon>
                        </div>
                        <div className='iconos' onClick={()=>{
                            window.open("https://api.whatsapp.com/send?phone=5218711104926", "_blank");
                        }}>
                            <WhatsAppIcon></WhatsAppIcon>
                        </div>  
                        <div className='iconos' onClick={()=>{
                            window.open("https://instagram.com/curielarquitectos?igshid=MzRlODBiNWFlZA==", "_blank");
                        }}>
                            <InstagramIcon></InstagramIcon>
                        </div>
                        <div className='iconos' onClick={()=>{
                            window.location.href = "mailto:info@curielarquitectos.com";
                        }}>
                            <EmailIcon></EmailIcon>
                        </div>
                    </div>
                </div>
                <div className='grid-colum-footer'>
                    <div className='gcf1'>
                        <p onClick={()=>window.location="./about"}>{props.ingles===true?"About Us":"Nosotros"}</p>
                    </div>
                    <div className='gcf2'>
                        <p onClick={()=>window.location="./services"}>{props.ingles===true?"Services":"Servicios"}</p>
                    </div>
                    <div className='gcf3'>
                        <p onClick={()=>window.location="./projects"}>{props.ingles===true?"Gallery":"Galería"}</p>
                    </div>
                    <div className='gcf4'>
                        <p onClick={()=>window.location="./team"}>{props.ingles===true?"Team":"Equipo"}</p>
                    </div>
                    <div className='gcf5'>
                        <p onClick={()=>window.location="./contact"}>{props.ingles===true?"Contact":"Contacto"}</p>
                    </div>
                </div>
                <p className='copyr'>{props.ingles===true?"Curiel Arquitectos 2023 © All rights reserved":"Curiel Arquitectos 2023 © Todos los derechos reservados"}</p>
            </div>
        </div>
    </div>
  )
}
