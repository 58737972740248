import React, { useEffect, useState } from 'react';
import HeroSlider, { Overlay, Slide, MenuNav, Nav, AutoplayButton } from "hero-slider";
import "./slider.css";
import MenuIcon from '@mui/icons-material/Menu';
import { MENULATERAL } from '../menulat/menulat';

import slide1 from "../../images/Home/1.jpg";
import slide2 from "../../images/Home/2.jpg";
import slide3 from "../../images/Home/3.jpg";
import slide4 from "../../images/Home/4.jpg";
import slide5 from "../../images/Home/5.png";
import slide6 from "../../images/Home/6.jpg";
import slide7 from "../../images/Home/7.png";
import slide8 from "../../images/Home/8.jpg";
import slide9 from "../../images/Home/9.png";
import slide10 from "../../images/Home/10.jpg";
import slide11 from "../../images/Home/11.jpg";
import slide12 from "../../images/Home/12.png";
import slide13 from "../../images/Home/13.jpg";
import slide14 from "../../images/Home/14.jpg";


import logo_curiel from "../../images/Logo_Blanco_SinFondo.png";
import logo_curiel_b from "../../images/Logo_curiel_black.png";





export const SLIDER = (props) => {
    const [loaded, setLoaded] = useState(0);
  
    useEffect(() => {
      const timer = setInterval(() => {
        setLoaded((prevProgress) => prevProgress + 1);
      }, 100); 
  
      if (loaded >= 100) {
        clearInterval(timer);
        setLoaded(0);
      }
      return () => {
        clearInterval(timer);
      };
    }, [loaded]);
    
    
    const [menuVisible, setMenuVisible] = useState(false);


    const handleMouseEnter = (event) => {
      if (event.clientX >= window.innerWidth - 5) {
        setMenuVisible(true);
      } else {
        setMenuVisible(false);
      }
    };
  return (
    <>
      <div className='container-slider' id='home' onMouseMove={handleMouseEnter}>
        <div className='pestaña-des' onClick={()=>setMenuVisible(true)}>
          <MenuIcon></MenuIcon>
        </div>
        
        <HeroSlider
            autoplay
            height="100vh"
            
            controller={{
              initialSlide: 1,
              slidingDuration: 1000,
              slidingDelay: 0,
              onSliding: (nextSlide) =>
                console.debug("onSliding(nextSlide): ", nextSlide, setLoaded(0)),
              onBeforeSliding: (previousSlide, nextSlide) =>
                console.debug(
                  "onBeforeSliding(previousSlide, nextSlide): ",
                  previousSlide,
                  nextSlide,
                  setLoaded(0)
                ),
              onAfterSliding: (nextSlide) =>
                console.debug("onAfterSliding(nextSlide): ", nextSlide, setLoaded(0))
            }}
        >
        <Slide
          background={{
            maskBackgroundBlendMode: 'luminosity',
            backgroundImageSrc: slide1,
            backgroundAnimation: 'fade'     
          }}
        >
          <Overlay>
            <div className='container-text'>
              <img src={logo_curiel} className='logo-ca'></img>
            </div>
          </Overlay>

        </Slide>
        <Slide
          background={{
            maskBackgroundBlendMode: 'luminosity',
            backgroundImageSrc: slide2,
            backgroundAnimation: 'fade' 
          }}
        >
          <Overlay>
              <div className='container-text'>
                <img src={logo_curiel} className='logo-ca'></img>
              </div>
          </Overlay>
        </Slide>
        <Slide  
          background={{
            maskBackgroundBlendMode: 'luminosity',
            backgroundImageSrc: slide3,
            backgroundAnimation: 'fade',
          }}
        >
          <Overlay>
              <div className='container-text'>
                <img src={logo_curiel} className='logo-ca'></img>
              </div>
          </Overlay>
        </Slide>
        <Slide  
          background={{
            maskBackgroundBlendMode: 'luminosity',
            backgroundImageSrc: slide4,
            backgroundAnimation: 'fade' 
          }}
        >
          <Overlay>
              <div className='container-text'>
                <img src={logo_curiel} className='logo-ca'></img>
              </div>
          </Overlay>
        </Slide>      
        <Slide  
          background={{
            maskBackgroundBlendMode: 'luminosity',
            backgroundImageSrc: slide5,
            backgroundAnimation: 'fade' 
          }}
        >
        <Overlay>
            <div className='container-text'>
              <img src={logo_curiel_b} className='logo-ca'></img>
            </div>
        </Overlay>
        </Slide>
        <Slide  
          background={{
            maskBackgroundBlendMode: 'luminosity',
            backgroundImageSrc: slide6,
            backgroundAnimation: 'fade' 
          }}
        >
        <Overlay>
            <div className='container-text'>
              <img src={logo_curiel_b} className='logo-ca'></img>
            </div>
        </Overlay>
        </Slide>
        <Slide  
          background={{
            maskBackgroundBlendMode: 'luminosity',
            backgroundImageSrc: slide7,
            backgroundAnimation: 'fade' 
          }}
        >
          <Overlay>
              <div className='container-text'>
                <img src={logo_curiel_b} className='logo-ca'></img>
              </div>
          </Overlay>
        </Slide>
        <Slide  
          background={{
            maskBackgroundBlendMode: 'luminosity',
            backgroundImageSrc: slide8,
            backgroundAnimation: 'fade' 
          }}
        >
          <Overlay>
              <div className='container-text'>
                <img src={logo_curiel_b} className='logo-ca'></img>
              </div>
          </Overlay>
        </Slide>
        <Slide  
          background={{
            maskBackgroundBlendMode: 'luminosity',
            backgroundImageSrc: slide9,
            backgroundAnimation: 'fade' 
          }}
        >
          <Overlay>
              <div className='container-text'>
                <img src={logo_curiel_b} className='logo-ca'></img>
              </div>
          </Overlay>
        </Slide>
        <Slide  
          background={{
            maskBackgroundBlendMode: 'luminosity',
            backgroundImageSrc: slide10,
            backgroundAnimation: 'fade' 
          }}
        >
          <Overlay>
              <div className='container-text'>
                <img src={logo_curiel_b} className='logo-ca'></img>
              </div>
          </Overlay>
        </Slide>
        <Slide  
          background={{
            maskBackgroundBlendMode: 'luminosity',
            backgroundImageSrc: slide11,
            backgroundAnimation: 'fade' 
          }}
        >
          <Overlay>
              <div className='container-text'>
                <img src={logo_curiel} className='logo-ca'></img>
              </div>
          </Overlay>
        </Slide>
        <Slide  
          background={{
            maskBackgroundBlendMode: 'luminosity',
            backgroundImageSrc: slide12,
            backgroundAnimation: 'fade' 
          }}
        >
          <Overlay>
              <div className='container-text'>
                <img src={logo_curiel} className='logo-ca'></img>
              </div>
          </Overlay>
        </Slide>
        <Slide  
          background={{
            maskBackgroundBlendMode: 'luminosity',
            backgroundImageSrc: slide13,
            backgroundAnimation: 'fade' 
          }}
        >
          <Overlay>
              <div className='container-text'>
                <img src={logo_curiel} className='logo-ca'></img>
              </div>
          </Overlay>
        </Slide>
        <Slide  
          background={{
            maskBackgroundBlendMode: 'luminosity',
            backgroundImageSrc: slide14,
            backgroundAnimation: 'fade' 
          }}
        >
          <Overlay>
              <div className='container-text'>
                <img src={logo_curiel_b} className='logo-ca'></img>
              </div>
          </Overlay>
        </Slide>
      <Nav></Nav>
      </HeroSlider>      
      </div>

        <div className={`menu ${menuVisible ? 'visible' : ''}`}>
          <MENULATERAL ingles={props.ingles} setingles={props.setingles}></MENULATERAL>
        </div>    
    </>
    
  )
}
