import "./contact.css";
import {useRef, useState} from "react";
import { FOOTERC } from '../footer/footer';
import { NAVBAR } from '../navbar/navbar';
import ReCAPTCHA from "react-google-recaptcha";
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import axios from "axios";

export const CONTACT = () => {

    const captcha = useRef(null);
    const [name, setname] = useState("");
    const [email, setemail] = useState("");
    const [city, setcity] = useState("");
    const [msj, setmsj] = useState("");

    const [ingles, setingles]=useState(
        localStorage.getItem("ingles_curiel_web") === null
          ? false
          : localStorage.getItem("ingles_curiel_web") === "true"
          ? true
          : false
    );

    const verificar = () =>{
        if(captcha.current.getValue()){
            console.log("El usuario no es un robot");
        }
    }

    const verificarcorreo =(email)=>{
        const regexCorreo = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return regexCorreo.test(email);
    }


    const enviarCorreo = ()=> {
        
        if (captcha.current.getValue()) {

            if(name==="" || name?.trim()=="" || email === "" || email?.trim()==="" || msj === ""){
                alert("Complete los campos del formulario");
                return null;
            }           
            const d = verificarcorreo(email);
            if(d===false){
                alert("Correo no válido");
                return null;
            }

            axios.post('https://4akuhejwr1.execute-api.us-east-1.amazonaws.com/prod/send-email',
                { 
                    name: name, 
                    email: email,
                    city: city,
                    message: msj,      
                }
            )
            .then(function (res) {
                console.log(res)
                setname("");
                setcity("");
                setemail("");
                setmsj("");
               
            })
            .catch(function (err) {
                console.log(err);
            });

        }else{
            alert("Completa el CAPTCHA antes de enviar el formulario.");
            return;
        }
    }


  return (
    <>
    <NAVBAR ingles={ingles} setingles={setingles}></NAVBAR>
    <div className='contact-container' id="contact-container">
        <div className='capa-contact'></div>
        <div className='contact-grid'>
            <div className='formulario'>
                <h2>{ingles===true?"Contact":"Contacto"}</h2>
                <div className="formulario-inputs">
                    <input placeholder={ingles===true?"Name":'Nombre'} value={name} onChange={(e)=>setname(e.target.value)}></input>
                    <input placeholder={ingles===true?"Email":'Correo Electrónico'} value={email} onChange={(e)=>setemail(e.target.value)}></input>
                    <input placeholder={ingles===true?"City":'Ciudad'} value={city} onChange={(e)=>setcity(e.target.value)}></input>
                    <textarea placeholder={ingles===true?"Message":'Mensaje'} value={msj} onChange={(e)=>setmsj(e.target.value)}></textarea>
                </div>
                <div className='input-captcha'>
                        <ReCAPTCHA
                            ref={captcha}
                            sitekey="6LfEcccoAAAAAK7o0p9YTeFRYvMKgVhWaovWsz3T"
                            onChange={verificar}
                        />
                </div>
                <div className='button-enviar'>
                    <button onClick={()=>enviarCorreo()}>{ingles===true?"SUBMIIT":"ENVIAR"}</button>
                </div>
                
            </div>
            <div className='mapadir'>
                <h2>{ingles===true?"Address":"Dirección"}</h2>
                <div className="dir-grid-contac">
                    <div className="dir-map1">
                        <p className='dirmap'>
                            {
                                ingles===true?
                                "Northern Office: Lisboa 300, between Paris street and Roma Street, San Isidro Neighborhood, Zip Code 27100, Torreón, Coahuila, México.":
                                "Oficina Norte: Lisboa 300 entre Calle Paris y Calle Roma, Colonia San Isidro, CP 27100, Torreón Coahuila, México."
                            }
                        </p>
                        <iframe loading='lazy' src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3599.184044191846!2d-103.43372579999999!3d25.565542999999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x868fda36ad1eaba5%3A0x9b10de3e3abf3251!2sCalle+Lisboa+300%2C+San+Isidro%2C+27100+Torre%C3%B3n%2C+Coah.!5e0!3m2!1ses-419!2smx!4v1438640052404'></iframe>
                    </div>
                    <div className="line">
                        <div></div>
                    </div>
                    <div className="dir-map2">
                        <p className="dirmap">
                            {
                                ingles===true?
                                "Central Office: Prado Norte 225, Lomas de Chapultepec, Miguel Hidalgo, Zip Code 11000, Mexico City (CDMX), Mexico":
                                "Oficina Centro: Prado Norte 225, Lomas de Chapultepec, Miguel Hidalgo, 11000 Ciudad de México, CDMX"
                            }
                        </p>
                        <iframe loading="lazy" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d817.8683365686402!2d-99.20735438211061!3d19.428110951038196!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d201f5dd85be03%3A0x623fafca7762465!2sAvenida%20Prado%20Norte%20225-Int.%20312%2C%20Lomas%20-%20Virreyes%2C%20Lomas%20de%20Chapultepec%2C%20Miguel%20Hidalgo%2C%2011000%20Ciudad%20de%20M%C3%A9xico%2C%20CDMX!5e0!3m2!1ses-419!2smx!4v1698165184174!5m2!1ses-419!2smx"></iframe>
                    </div>
                </div>
                {/* <div className="redessociales">
                    <h2 className="h2red">Nuestras Redes</h2>
                    <p className="pred">prueba@curielarquitectos.com</p>
                    <div className="redes-list">
                        <p><FacebookIcon></FacebookIcon></p>
                        <p><WhatsAppIcon></WhatsAppIcon></p>
                        <p><InstagramIcon></InstagramIcon></p>
                    </div>
                    
                </div> */}
            </div>
            
        </div>
    </div>
    <FOOTERC ingles={ingles}></FOOTERC>
    </>
    
  )
}
