import React from 'react'
import "./menulat.css";
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import logo_curiel_b from "../../images/Logo_curiel_black.png";
import EmailIcon from '@mui/icons-material/Email';

export const MENULATERAL = (props) => {

  return (
    <div className='menu-lateral-container'>
        <img className="logohor" src={logo_curiel_b} alt=''></img>
        <div className='items'>
            <p onClick={()=>window.location="/"}>{props.ingles===true?"HOME":"INICIO"}</p>
            <p onClick={()=>window.location="/about"}>{props.ingles===true?"ABOUT US":"NOSOTROS"}</p>
            <p onClick={()=>window.location="/services"}>{props.ingles===true?"SERVICES":"SERVICIOS"}</p>
            <p onClick={()=>window.location="/projects"}>{props.ingles===true?"GALLERY":"GALERÍA"}</p>
            <p onClick={()=>window.location="/team"}>{props.ingles===true?"TEAM":"EQUIPO"}</p>
            <p onClick={()=>window.location="/contact"}>{props.ingles===true?"CONTACT":"CONTACTO"}</p>
            {
              props.ingles===true?
              <p onClick={()=>{
                props.setingles(false)
                localStorage.setItem("ingles_curiel_web", "false");  
              }}
              >ES</p>:
              <p onClick={()=>{
                props.setingles(true)
                localStorage.setItem("ingles_curiel_web", "true");
              }}>EN</p>
            }
        </div>
        <div className='redes-sociales'>
            <p><FacebookIcon onClick={()=>window.open("https://www.facebook.com/curielarquitectos", "_blank")}></FacebookIcon></p>
            <p><WhatsAppIcon onClick={()=>{window.open("https://api.whatsapp.com/send?phone=5218711104926", "_blank")}}></WhatsAppIcon></p>
            <p><InstagramIcon onClick={()=>{window.open("https://instagram.com/curielarquitectos?igshid=MzRlODBiNWFlZA==", "_blank")}}></InstagramIcon></p>
            <p><EmailIcon onClick={()=>{window.location.href = "mailto:info@curielarquitectos.com"}}></EmailIcon></p>
        </div>
    </div>
  )
}
