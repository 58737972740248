import React, { useEffect, useState } from 'react'
import "./ordera.css";
import { data_images_gallery } from '../tools/gallery';


export const ORDER_A = (props) => {

  const tipo1 = (<p className='ptext'>{props.ingles===true?"Residential":"Residencial"}</p>)
  const tipo2 = (<p className='ptext'>{props.ingles===true?"Commercial":"Comercial"}</p>)


  const [data, setdata]=useState([]);

  const redirec=(item)=>{
    console.log(item);
    localStorage.setItem("viewprojectcuriel", item?.name);
    window.location="/view_project"
  }


  useEffect(() => {
    ordenamiento();
  }, [])

  const ordenamiento = () =>{
    const d = data_images_gallery();
    const grupos = [];
    let tipo = 'grupoa';
    for (let i = 0; i < d.length; ) {
        const grupoA = d.slice(i, i + 2);
        i += 2;
        const grupoB = d.slice(i, i + 3);
        i += 3;
        const grupoC = d.slice(i, i + 2);
        i += 2;

        if (grupoA.length > 0) grupos.push({ tipo, ["grupo"]: grupoA });
        tipo = 'grupob'; // Cambia al siguiente tipo
        if (grupoB.length > 0) grupos.push({ tipo, ["grupo"]: grupoB });
        tipo = 'grupoc'; // Cambia al siguiente tipo
        if (grupoC.length > 0) grupos.push({ tipo, ["grupo"]: grupoC });
        tipo = 'grupoa'; // Reinicia el tipo
    }   
    setdata(grupos);
  }

  return (
    <div className='seccion-proyect' id='seccion-proyect'>
        <div className='capa-pro'></div>
            {
                data?.map((item, index)=>{
                    if(item?.tipo==="grupoa"){
                        return(
                            <div className='residencial-grid1'>
                               {
                                 item?.grupo?.map((jtem, jndex)=>{
                                    return(
                                        <div className={`grupoa-${jndex+1} res-cart`} style={{"backgroundImage":`url(${jtem?.portada})`}} onClick={()=>redirec(jtem)}>
                                            <div className=''>
                                                <p className='ptitle'>{jtem?.name}</p>
                                                <br></br>
                                            </div>    
                                        </div>
                                    )
                                 })
                               }
                            </div>  
                        )
                    }else if(item?.tipo==="grupob"){
                        return(
                            <div className='residencial-grid2'>
                                {
                                    item?.grupo?.map((jtem, jndex)=>{
                                        return(
                                            <div className={`grupoab-${jndex+1} res-cart`} style={{"backgroundImage":`url(${jtem?.portada})`}} onClick={()=>redirec(jtem)}>
                                                <div className=''>
                                                    <p className='ptitle'>{jtem?.name}</p>
                                                    <br></br>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        )
                    }else if(item?.tipo==="grupoc"){
                        return(
                            <div className='residencial-grid3'>
                                {
                                    item?.grupo?.map((jtem, jndex)=>{
                                        return(
                                            <div className={`grupoc-${jndex+1} res-cart`} style={{"backgroundImage":`url(${jtem?.portada})`}} onClick={()=>redirec(jtem)}>
                                                <div className=''>
                                                    <p className='ptitle'>{jtem?.name}</p>
                                                    <br></br>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        )
                    }
                })
            }
        </div>
  )
}
