import React from 'react'
import "./home.css";
import { SLIDER } from './slider/slider';
import { useState } from 'react';
export const HOME = () => {

  const [ingles, setingles]=useState(
    localStorage.getItem("ingles_curiel_web") === null
      ? false
      : localStorage.getItem("ingles_curiel_web") === "true"
      ? true
      : false
  );

  window.onscroll = function() {
    var button = document.getElementById("scrollToTopButton");
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
      document.getElementById("scrollToTopButton").classList.remove("active"); 
      button.style.display = "block";
    } else {
      document.getElementById("scrollToTopButton").classList.add("active"); 
      setTimeout(() => {
         button.style.display = "none";
      }, 1000);  
    }
  };

  const scrollToTop = () => {

   const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
   if (currentScroll > 0) {
     const scrollStep = Math.ceil(currentScroll / 10);      
     window.scrollTo(0, currentScroll - scrollStep);
     requestAnimationFrame(scrollToTop);
   }
 };

  return (
    <div className='home-container'>
        <SLIDER ingles={ingles} setingles={setingles}></SLIDER>
    </div>
  )
}
