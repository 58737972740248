import React, { useEffect } from 'react'
import "./navbar.css";
import { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import logo from "../../images/Logo_curiel_black.png";

export const NAVBAR = (props) => {

    const[menu, setmenu]=useState(false);


    useEffect(() => {
        
        if (menu === true) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'visible'; // O 'auto' si prefieres restaurar el scroll
        }
        return () => {
            document.body.style.overflow = 'visible'; // O 'auto' si es necesario
        };
    }, [menu])


    const [ullist, setullist]=useState(false);


  return (
    <div className='navbar_container'>
        <div className='iconos-navbar'>
            <img src={logo}></img>
        </div>
        <div className='navbar-seccion-grid'>
            <div className='menu-items'>
                <ul className='ul-items'>
                    <li><a href='/'>{props.ingles===true?"Home":"Inicio"}</a></li>
                    <li><a href={props.op===1?"#":"/about"}>{props.ingles===true?"About Us":"Nosotros"}</a></li>  
                    <li><a href='/services'>{props.ingles===true?"Services":"Servicios"}</a></li>
                    <li><a href='/projects'>{props.ingles===true?"Gallery":"Galería"}</a></li>
                    <li><a href='/team'>{props.ingles===true?"Team":"Equipo"}</a></li>
                    <li><a href='/contact'>{props.ingles===true?"Contact":"Contacto"}</a></li>
                    {
                        props.ingles===true?
                        <li><a href='#' onClick={()=>{
                            props.setingles(false)
                            localStorage.setItem("ingles_curiel_web", "false");
                        }}>Es</a></li>:
                        <li><a href='#' onClick={()=>{
                            props.setingles(true)
                            localStorage.setItem("ingles_curiel_web", "true");
                        }}>En</a></li>
                    }
                </ul>
            </div>
            <MenuIcon className='menu-cel' onClick={()=>setmenu(!menu)}></MenuIcon>
            {
            menu===true?    
                <div className='menu-flotante-cel-hi'>
                    <img src={logo} className='logo-curiel-menu-cel'></img>
                    <MenuIcon className='menu-cel' onClick={()=>setmenu(!menu)}></MenuIcon>
                    <ul className='menu-flotante-items'>
                        <li><a href="./about" onClick={()=>setmenu(false)} data-section="#about" id="item2">{props.ingles===true?"ABOUT US":"NOSOTROS"}</a></li>
                        <li><a href="./services" onClick={()=>setmenu(false)} data-section="#services-cloud-soluciones" id="item2">{props.ingles===true?"SERVICES":"SERVICIOS"}</a></li>
                        <li><a href='./projects' onClick={()=>setmenu(false)}>{props.ingles===true?"GALLERY":"GALERIA"}</a></li>
                        <li><a href='./team'>{props.ingles===true?"TEAM":"EQUIPO"}</a></li>
                        <li><a href='./contact'>{props.ingles===true?"CONTACT":"CONTACTO"}</a></li>
                        <li className='menu-ingles-celular' onClick={()=>setullist(!ullist)}>
                            <a>{props.ingles===true?"LANGUAGE":"IDIOMA"}<ArrowDropDownIcon></ArrowDropDownIcon></a>
                        
                            {
                                ullist===true?
                                <ul>{
                                        props.ingles===true?
                                        <li onClick={()=>{
                                            props.setingles(false);
                                            localStorage.setItem("ingles_curiel_web", "false");
                                        }}>ES</li>:
                                        <li onClick={()=>{
                                            props.setingles(true);
                                            localStorage.setItem("ingles_curiel_web", "true");
                                        }}>EN</li>
                                    }
                                </ul>:
                                <></>
                            }
                        </li>
                    </ul>
                </div>:
                <></>
            }
        </div>
    </div>
  )
}
