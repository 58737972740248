import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { HOME } from './pages/Home';
import { NOSOTROS } from './pages/nosotros/nosotros';
import { SECCION } from './pages/seccion/seccion';
import { PROYECTOS } from './pages/project/projects';
import { VIEW_PROJECT } from './pages/project/tools/viewproject';
import { CONTACT } from './pages/contact/contact';
import { TEAM } from './pages/team/team.jsx';
import { useState } from 'react';

function App() {
  
  return (
    <div>
      <Router>
        <Switch>
          <Route path={"/"} exact component={HOME}></Route>
          <Route path={"/about"} exact component={NOSOTROS}></Route>
          <Route path={"/services"} exact component={SECCION}></Route>
          <Route path={"/projects"} exact component={PROYECTOS}></Route>
          <Route path={"/view_project"} exact component={VIEW_PROJECT}></Route>
          <Route path={"/contact"} exact component={CONTACT}></Route>
          <Route path={"/team"} exact component={TEAM}></Route>
          <Redirect to="/"></Redirect>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
