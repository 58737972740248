import PAC1 from "../../../images/Galeria/Proyecto AC/01A.png";
import PAC2 from "../../../images/Galeria/Proyecto AC/02A.png";
import PAC3 from "../../../images/Galeria/Proyecto AC/03A.png";
import PAC4 from "../../../images/Galeria/Proyecto AC/04A.png";
import PAC5 from "../../../images/Galeria/Proyecto AC/05A.png";
import PAC6 from "../../../images/Galeria/Proyecto AC/06A.png";

import PAR1 from "../../../images/Galeria/Proyecto AR/T01.jpg";
import PAR2 from "../../../images/Galeria/Proyecto AR/T02.jpg";
import PAR3 from "../../../images/Galeria/Proyecto AR/T03.jpg";
import PAR4 from "../../../images/Galeria/Proyecto AR/T04.jpg";
import PAR5 from "../../../images/Galeria/Proyecto AR/T05.jpg";
import PAR6 from "../../../images/Galeria/Proyecto AR/T06.jpg";
import PAR7 from "../../../images/Galeria/Proyecto AR/T07.jpg";
import PAR8 from "../../../images/Galeria/Proyecto AR/T08.jpg";
import PAR9 from "../../../images/Galeria/Proyecto AR/T09.jpg";
import PAR10 from "../../../images/Galeria/Proyecto AR/T10.jpg";
import PAR11 from "../../../images/Galeria/Proyecto AR/T11.jpg";
import PAR12 from "../../../images/Galeria/Proyecto AR/T12.jpg";
import PAR13 from "../../../images/Galeria/Proyecto AR/T13.jpg";
import PAR14 from "../../../images/Galeria/Proyecto AR/T14.jpg";
import PAR15 from "../../../images/Galeria/Proyecto AR/T15.jpg";
import PAR16 from "../../../images/Galeria/Proyecto AR/T16.jpg";
import PAR17 from "../../../images/Galeria/Proyecto AR/T17.jpg";
import PAR18 from "../../../images/Galeria/Proyecto AR/T19.jpg";
import PAR19 from "../../../images/Galeria/Proyecto AR/T20.jpg";
import PAR20 from "../../../images/Galeria/Proyecto AR/T21.jpg";
import PAR21 from "../../../images/Galeria/Proyecto AR/T23.jpg";

import PBL1 from "../../../images/Galeria/Proyecto BL/09.png";
import PBL2 from "../../../images/Galeria/Proyecto BL/12.png";
import PBL3 from "../../../images/Galeria/Proyecto BL/15.png";
import PBL4 from "../../../images/Galeria/Proyecto BL/21.png";
import PBL5 from "../../../images/Galeria/Proyecto BL/22.png";
import PBL6 from "../../../images/Galeria/Proyecto BL/25.png";
import PBL7 from "../../../images/Galeria/Proyecto BL/26.png";

import PCC1 from "../../../images/Galeria/Proyecto CC/001.png";
import PCC2 from "../../../images/Galeria/Proyecto CC/01C5C.png";
import PCC3 from "../../../images/Galeria/Proyecto CC/002.png";
import PCC4 from "../../../images/Galeria/Proyecto CC/02C5C.png";
import PCC5 from "../../../images/Galeria/Proyecto CC/003.png";
import PCC6 from "../../../images/Galeria/Proyecto CC/03C5C.png";
import PCC7 from "../../../images/Galeria/Proyecto CC/05C5C.png";
import PCC8 from "../../../images/Galeria/Proyecto CC/06C5C.png";

import PCP1 from "../../../images/Galeria/Proyecto CP/CP001.png";
import PCP2 from "../../../images/Galeria/Proyecto CP/CP002.png";
import PCP3 from "../../../images/Galeria/Proyecto CP/CP003.png";
import PCP4 from "../../../images/Galeria/Proyecto CP/CP004.png";
import PCP5 from "../../../images/Galeria/Proyecto CP/CP005.png";
import PCP6 from "../../../images/Galeria/Proyecto CP/CP006.png";
import PCP7 from "../../../images/Galeria/Proyecto CP/CP007.png";
import PCP8 from "../../../images/Galeria/Proyecto CP/CP008.png";
import PCP9 from "../../../images/Galeria/Proyecto CP/CP009.png";
import PCP10 from "../../../images/Galeria/Proyecto CP/CP010.png";
import PCP11 from "../../../images/Galeria/Proyecto CP/CP011.png";

import PCR1 from "../../../images/Galeria/Proyecto CR/22.png";
import PCR2 from "../../../images/Galeria/Proyecto CR/111.png";
import PCR3 from "../../../images/Galeria/Proyecto CR/333.png";
import PCR4 from "../../../images/Galeria/Proyecto CR/444.png";
import PCR5 from "../../../images/Galeria/Proyecto CR/555.png";
import PCR6 from "../../../images/Galeria/Proyecto CR/666.png";

import PGL1 from "../../../images/Galeria/Proyecto GL/09.png";
import PGL2 from "../../../images/Galeria/Proyecto GL/10.png";
import PGL3 from "../../../images/Galeria/Proyecto GL/13.png";
import PGL4 from "../../../images/Galeria/Proyecto GL/16.png";
import PGL5 from "../../../images/Galeria/Proyecto GL/17.png";
import PGL6 from "../../../images/Galeria/Proyecto GL/18.png";
import PGL7 from "../../../images/Galeria/Proyecto GL/21.png";
import PGL8 from "../../../images/Galeria/Proyecto GL/22.png";
import PGL9 from "../../../images/Galeria/Proyecto GL/23.png";
import PGL10 from "../../../images/Galeria/Proyecto GL/24.png";
import PGL11 from "../../../images/Galeria/Proyecto GL/25.png";
import PGL12 from "../../../images/Galeria/Proyecto GL/27.png";
import PGL13 from "../../../images/Galeria/Proyecto GL/28.png";
import PGL14 from "../../../images/Galeria/Proyecto GL/29.png";
import PGL15 from "../../../images/Galeria/Proyecto GL/30.png";
import PGL16 from "../../../images/Galeria/Proyecto GL/31.png";
import PGL17 from "../../../images/Galeria/Proyecto GL/32.png";
import PGL18 from "../../../images/Galeria/Proyecto GL/33.png";
import PGL19 from "../../../images/Galeria/Proyecto GL/34.png";
import PGL20 from "../../../images/Galeria/Proyecto GL/35.png";
import PGL21 from "../../../images/Galeria/Proyecto GL/36.png";
import PGL22 from "../../../images/Galeria/Proyecto GL/37.png";
import PGL23 from "../../../images/Galeria/Proyecto GL/38.png";
import PGL24 from "../../../images/Galeria/Proyecto GL/39.png";
import PGL25 from "../../../images/Galeria/Proyecto GL/40.png";
import PGL26 from "../../../images/Galeria/Proyecto GL/41.png";
import PGL27 from "../../../images/Galeria/Proyecto GL/42.png";
import PGL28 from "../../../images/Galeria/Proyecto GL/44.png";

import PGR1 from "../../../images/Galeria/Proyecto GW/gw1.jpg";
import PGR2 from "../../../images/Galeria/Proyecto GW/gw2.jpg";
import PGR3 from "../../../images/Galeria/Proyecto GW/gw3.jpg";
import PGR4 from "../../../images/Galeria/Proyecto GW/gw4.jpg";
import PGR5 from "../../../images/Galeria/Proyecto GW/gw1.jpg";
import PGR6 from "../../../images/Galeria/Proyecto GW/gw6.jpg";
import PGR7 from "../../../images/Galeria/Proyecto GW/gw7.jpg";
import PGR8 from "../../../images/Galeria/Proyecto GW/gw8.jpg";
import PGR9 from "../../../images/Galeria/Proyecto GW/gw9.jpg";
import PGR10 from "../../../images/Galeria/Proyecto GW/gw10.jpg";
import PGR11 from "../../../images/Galeria/Proyecto GW/gw11.jpg";
import PGR12 from "../../../images/Galeria/Proyecto GW/gw12.jpg";

import PJZ1 from "../../../images/Galeria/Proyecto JZ/01JAZ.png";
import PJZ2 from "../../../images/Galeria/Proyecto JZ/02JAZ.png";
import PJZ3 from "../../../images/Galeria/Proyecto JZ/03JAZ.png";
import PJZ4 from "../../../images/Galeria/Proyecto JZ/04JAZ.png";
import PJZ5 from "../../../images/Galeria/Proyecto JZ/05JAZ.png";

import PLB1 from "../../../images/Galeria/Proyecto LB/L01.png";
import PLB2 from "../../../images/Galeria/Proyecto LB/L02.png";
import PLB3 from "../../../images/Galeria/Proyecto LB/L03.png";
import PLB4 from "../../../images/Galeria/Proyecto LB/L04.png";
import PLB5 from "../../../images/Galeria/Proyecto LB/L05.png";
import PLB6 from "../../../images/Galeria/Proyecto LB/L06.png";
import PLB7 from "../../../images/Galeria/Proyecto LB/L07.png";
import PLB8 from "../../../images/Galeria/Proyecto LB/L08.png";
import PLB9 from "../../../images/Galeria/Proyecto LB/MUEBLETV11.jpg";
import PLB10 from "../../../images/Galeria/Proyecto LB/MUEBLETV22.jpg";
import PLB11 from "../../../images/Galeria/Proyecto LB/MUEBLETV33.jpg";

import PLC1 from "../../../images/Galeria/Proyecto LC/01.png";
import PLC2 from "../../../images/Galeria/Proyecto LC/02.png";
import PLC3 from "../../../images/Galeria/Proyecto LC/03.png";
import PLC4 from "../../../images/Galeria/Proyecto LC/04.png";
import PLC5 from "../../../images/Galeria/Proyecto LC/05.png";

import PLD1 from "../../../images/Galeria/Proyecto LD/IMG_3691.jpg";
import PLD2 from "../../../images/Galeria/Proyecto LD/IMG_3700.jpg";
import PLD3 from "../../../images/Galeria/Proyecto LD/IMG_3708.jpg";
import PLD4 from "../../../images/Galeria/Proyecto LD/IMG_3741.jpg";
import PLD5 from "../../../images/Galeria/Proyecto LD/IMG_3792.jpg";
import PLD6 from "../../../images/Galeria/Proyecto LD/IMG_3813.jpg";
import PLD7 from "../../../images/Galeria/Proyecto LD/IMG_3871.jpg";
import PLD8 from "../../../images/Galeria/Proyecto LD/IMG_3895.jpg";
import PLD9 from "../../../images/Galeria/Proyecto LD/IMG_3918.jpg";
import PLD10 from "../../../images/Galeria/Proyecto LD/IMG_3933.jpg";
import PLD11 from "../../../images/Galeria/Proyecto LD/IMG_3943.jpg";
import PLD12 from "../../../images/Galeria/Proyecto LD/IMG_3946.jpg";

import PMD1 from "../../../images/Galeria/Proyecto MD/221901_Curiel_01.jpg";
import PMD2 from "../../../images/Galeria/Proyecto MD/221901_Curiel_02.jpg";
import PMD3 from "../../../images/Galeria/Proyecto MD/221901_Curiel_03.jpg";
import PMD4 from "../../../images/Galeria/Proyecto MD/221901_Curiel_04.jpg";
import PMD5 from "../../../images/Galeria/Proyecto MD/221901_Curiel_05.jpg";
import PMD6 from "../../../images/Galeria/Proyecto MD/221901_Curiel_06.jpg";
import PMD7 from "../../../images/Galeria/Proyecto MD/221901_Curiel_08.jpg";

import PMG1 from "../../../images/Galeria/Proyecto MG/MAG001.png";
import PMG2 from "../../../images/Galeria/Proyecto MG/MAG002.png";
import PMG3 from "../../../images/Galeria/Proyecto MG/MAG003.png";
import PMG4 from "../../../images/Galeria/Proyecto MG/MAG004.png";
import PMG5 from "../../../images/Galeria/Proyecto MG/MAG006.png";
import PMG6 from "../../../images/Galeria/Proyecto MG/MAG008.png";

import PNS1 from "../../../images/Galeria/Proyecto NS/02.png";
import PNS2 from "../../../images/Galeria/Proyecto NS/03.png";
import PNS3 from "../../../images/Galeria/Proyecto NS/05.png";
import PNS4 from "../../../images/Galeria/Proyecto NS/06.png";
import PNS5 from "../../../images/Galeria/Proyecto NS/07.png";
import PNS6 from "../../../images/Galeria/Proyecto NS/08.png";
import PNS7 from "../../../images/Galeria/Proyecto NS/09.png";
import PNS8 from "../../../images/Galeria/Proyecto NS/10.png";
import PNS9 from "../../../images/Galeria/Proyecto NS/11.png";
import PNS10 from "../../../images/Galeria/Proyecto NS/12.png";
import PNS11 from "../../../images/Galeria/Proyecto NS/18.png";
import PNS12 from "../../../images/Galeria/Proyecto NS/19.png";
import PNS13 from "../../../images/Galeria/Proyecto NS/21.png";
import PNS14 from "../../../images/Galeria/Proyecto NS/23.png";
import PNS15 from "../../../images/Galeria/Proyecto NS/24.png";
import PNS16 from "../../../images/Galeria/Proyecto NS/25.png";
import PNS17 from "../../../images/Galeria/Proyecto NS/26.png";
import PNS18 from "../../../images/Galeria/Proyecto NS/27.png";
import PNS19 from "../../../images/Galeria/Proyecto NS/30.png";
import PNS20 from "../../../images/Galeria/Proyecto NS/38.png";
import PNS21 from "../../../images/Galeria/Proyecto NS/40.png";
import PNS22 from "../../../images/Galeria/Proyecto NS/45.png";
import PNS23 from "../../../images/Galeria/Proyecto NS/46.png";
import PNS24 from "../../../images/Galeria/Proyecto NS/47.png";
import PNS25 from "../../../images/Galeria/Proyecto NS/48.png";
import PNS26 from "../../../images/Galeria/Proyecto NS/49.png";
import PNS27 from "../../../images/Galeria/Proyecto NS/52.png";
import PNS28 from "../../../images/Galeria/Proyecto NS/53.png";
import PNS29 from "../../../images/Galeria/Proyecto NS/55.png";
import PNS30 from "../../../images/Galeria/Proyecto NS/56.png";
import PNS31 from "../../../images/Galeria/Proyecto NS/57.png";
import PNS32 from "../../../images/Galeria/Proyecto NS/59.png";
import PNS33 from "../../../images/Galeria/Proyecto NS/60.png";
import PNS34 from "../../../images/Galeria/Proyecto NS/63.png";
import PNS35 from "../../../images/Galeria/Proyecto NS/64.png";
import PNS36 from "../../../images/Galeria/Proyecto NS/67.png";
import PNS37 from "../../../images/Galeria/Proyecto NS/68.png";
import PNS38 from "../../../images/Galeria/Proyecto NS/69.png";

import PRB1 from "../../../images/Galeria/Proyecto RB/rb1.jpg";
import PRB2 from "../../../images/Galeria/Proyecto RB/rb2.jpg";
import PRB3 from "../../../images/Galeria/Proyecto RB/rb3.jpg";
import PRB4 from "../../../images/Galeria/Proyecto RB/rb4.jpg";
import PRB5 from "../../../images/Galeria/Proyecto RB/rb5.jpg";
import PRB6 from "../../../images/Galeria/Proyecto RB/rb6.jpg";
import PRB7 from "../../../images/Galeria/Proyecto RB/rb7.jpg";
import PRB8 from "../../../images/Galeria/Proyecto RB/rb8.jpg";
import PRB9 from "../../../images/Galeria/Proyecto RB/rb9.jpg";
import PRB10 from "../../../images/Galeria/Proyecto RB/rb10.jpg";
import PRB11 from "../../../images/Galeria/Proyecto RB/rb11.jpg";
import PRB12 from "../../../images/Galeria/Proyecto RB/rb12.jpg";
import PRB13 from "../../../images/Galeria/Proyecto RB/rb13.jpg";
import PRB14 from "../../../images/Galeria/Proyecto RB/rb14.jpg";
import PRB15 from "../../../images/Galeria/Proyecto RB/rb15.jpg";
import PRB16 from "../../../images/Galeria/Proyecto RB/rb16.jpg";
import PRB17 from "../../../images/Galeria/Proyecto RB/rb17.jpg";
import PRB18 from "../../../images/Galeria/Proyecto RB/rb18.jpg";
import PRB19 from "../../../images/Galeria/Proyecto RB/rb19.jpg";
import PRB20 from "../../../images/Galeria/Proyecto RB/rb20.jpg";
import PRB21 from "../../../images/Galeria/Proyecto RB/rb21.jpg";

import PRG1 from "../../../images/Galeria/Proyecto RG/01REG.png";
import PRG2 from "../../../images/Galeria/Proyecto RG/001REG.png";
import PRG3 from "../../../images/Galeria/Proyecto RG/002REG.png";
import PRG4 from "../../../images/Galeria/Proyecto RG/03REG.png";
import PRG5 from "../../../images/Galeria/Proyecto RG/003REG.png";
import PRG6 from "../../../images/Galeria/Proyecto RG/04REG.png";
import PRG7 from "../../../images/Galeria/Proyecto RG/004REG.png";
import PRG8 from "../../../images/Galeria/Proyecto RG/05REG.png";
import PRG9 from "../../../images/Galeria/Proyecto RG/005REG.png";
import PRG10 from "../../../images/Galeria/Proyecto RG/06REG.png";
import PRG11 from "../../../images/Galeria/Proyecto RG/006REG.png";
import PRG12 from "../../../images/Galeria/Proyecto RG/07REG.png";
import PRG13 from "../../../images/Galeria/Proyecto RG/007REG.png";
import PRG14 from "../../../images/Galeria/Proyecto RG/08REG.png";
import PRG15 from "../../../images/Galeria/Proyecto RG/008REG.png";
import PRG16 from "../../../images/Galeria/Proyecto RG/09REG.png";
import PRG17 from "../../../images/Galeria/Proyecto RG/10REG.png";

import PRM1 from "../../../images/Galeria/Proyecto RM/R2.jpg";
import PRM2 from "../../../images/Galeria/Proyecto RM/RM.jpg";
import PRM3 from "../../../images/Galeria/Proyecto RM/RM3.jpg";
import PRM4 from "../../../images/Galeria/Proyecto RM/RM4.jpg";
import PRM5 from "../../../images/Galeria/Proyecto RM/RM5.jpg";
import PRM6 from "../../../images/Galeria/Proyecto RM/RM6.jpg";

import PRS1 from "../../../images/Galeria/Proyecto RS/L011.jpg";
import PRS2 from "../../../images/Galeria/Proyecto RS/LO10.jpg";
import PRS3 from "../../../images/Galeria/Proyecto RS/LO011.jpg";
import PRS4 from "../../../images/Galeria/Proyecto RS/LO12.jpg";
import PRS5 from "../../../images/Galeria/Proyecto RS/LO13.jpg";
import PRS6 from "../../../images/Galeria/Proyecto RS/LO022.jpg";
import PRS7 from "../../../images/Galeria/Proyecto RS/LO033.jpg";
import PRS8 from "../../../images/Galeria/Proyecto RS/L011.jpg";
import PRS9 from "../../../images/Galeria/Proyecto RS/L011.jpg";
import PRS10 from "../../../images/Galeria/Proyecto RS/L011.jpg";

import PSB1 from "../../../images/Galeria/Proyecto SB/s1.png";
import PSB2 from "../../../images/Galeria/Proyecto SB/s2.png";
import PSB3 from "../../../images/Galeria/Proyecto SB/s3.png";
import PSB4 from "../../../images/Galeria/Proyecto SB/s4.png";
import PSB5 from "../../../images/Galeria/Proyecto SB/s5.png";
import PSB6 from "../../../images/Galeria/Proyecto SB/s6.png";
import PSB7 from "../../../images/Galeria/Proyecto SB/s8.png";

import PTM1 from "../../../images/Galeria/Proyecto TM/t1.jpg";
import PTM2 from "../../../images/Galeria/Proyecto TM/t2.jpg";
import PTM3 from "../../../images/Galeria/Proyecto TM/t3.jpg";
import PTM4 from "../../../images/Galeria/Proyecto TM/t4.jpg";
import PTM5 from "../../../images/Galeria/Proyecto TM/t5.jpg";
import PTM6 from "../../../images/Galeria/Proyecto TM/t6.jpg";
import PTM7 from "../../../images/Galeria/Proyecto TM/t7.jpg";
import PTM8 from "../../../images/Galeria/Proyecto TM/t8.jpg";
import PTM9 from "../../../images/Galeria/Proyecto TM/t9.jpg";

import PVS1 from "../../../images/Galeria/Proyecto VS/VS001.png";
import PVS2 from "../../../images/Galeria/Proyecto VS/VS002.png";
import PVS3 from "../../../images/Galeria/Proyecto VS/VS003.png";
import PVS4 from "../../../images/Galeria/Proyecto VS/VS004.png";
import PVS5 from "../../../images/Galeria/Proyecto VS/VS005.png";
import PVS6 from "../../../images/Galeria/Proyecto VS/VS006.png";
import PVS7 from "../../../images/Galeria/Proyecto VS/VS007.png";
import PVS8 from "../../../images/Galeria/Proyecto VS/VS008.png";
import PVS9 from "../../../images/Galeria/Proyecto VS/VS009.png";

import PSCR1 from "../../../images/Galeria/Proyectos CR/01.png";
import PSCR2 from "../../../images/Galeria/Proyectos CR/02.png";
import PSCR3 from "../../../images/Galeria/Proyectos CR/04.png";
import PSCR4 from "../../../images/Galeria/Proyectos CR/05.png";
import PSCR5 from "../../../images/Galeria/Proyectos CR/08.png";
import PSCR6 from "../../../images/Galeria/Proyectos CR/11.png";
import PSCR7 from "../../../images/Galeria/Proyectos CR/13.png";
import PSCR8 from "../../../images/Galeria/Proyectos CR/14.png";
import PSCR9 from "../../../images/Galeria/Proyectos CR/15.png";
import PSCR10 from "../../../images/Galeria/Proyectos CR/16.png";
import PSCR11 from "../../../images/Galeria/Proyectos CR/18.png";
import PSCR12 from "../../../images/Galeria/Proyectos CR/19.png";
import PSCR13 from "../../../images/Galeria/Proyectos CR/20.png";
import PSCR14 from "../../../images/Galeria/Proyectos CR/21.png";
import PSCR15 from "../../../images/Galeria/Proyectos CR/27.png";
import PSCR16 from "../../../images/Galeria/Proyectos CR/28.png";
import PSCR17 from "../../../images/Galeria/Proyectos CR/31.png";
import PSCR18 from "../../../images/Galeria/Proyectos CR/33.png";
import PSCR19 from "../../../images/Galeria/Proyectos CR/34.png";

export function data_images_gallery(){
    var data = [
        {
            name: "PROYECTO NS",
            portada: PNS34,
            fotos: [
               { id: "1", url: PNS1 },
               { id: "2", url: PNS2 },
               { id: "3", url: PNS3 },
               { id: "4", url: PNS4 },
               { id: "5", url: PNS5 },
               { id: "6", url: PNS6 },
               { id: "7", url: PNS7 },
               { id: "8", url: PNS8 },
               { id: "9", url: PNS9 },
               { id: "10", url: PNS10 },
               { id: "11", url: PNS11 },
               { id: "12", url: PNS12 },
               { id: "13", url: PNS13 },
               { id: "14", url: PNS14 },
               { id: "15", url: PNS15 },
               { id: "16", url: PNS16 },
               { id: "17", url: PNS17 },
               { id: "18", url: PNS18 },
               { id: "19", url: PNS19 },
               { id: "20", url: PNS20 },
               { id: "21", url: PNS21 },
               { id: "22", url: PNS22 },
               { id: "23", url: PNS23 },
               { id: "24", url: PNS24 },
               { id: "25", url: PNS25 },
               { id: "26", url: PNS26 },
               { id: "27", url: PNS27 },
               { id: "28", url: PNS28 },
               { id: "29", url: PNS29 },
               { id: "30", url: PNS30 },
               { id: "31", url: PNS31 },
               { id: "32", url: PNS32 },
               { id: "33", url: PNS33 },
               { id: "34", url: PNS34 },
               { id: "35", url: PNS35 },
               { id: "36", url: PNS36 },
               { id: "37", url: PNS37 },
               { id: "38", url: PNS38 }
           ]
       },
       
        {
            name: "PROYECTO AR",
            portada: PAR1,
            fotos: [
                { id: "1", url: PAR1 },
                { id: "2", url: PAR2 },
                { id: "3", url: PAR3 },
                { id: "4", url: PAR4 },
                { id: "5", url: PAR5 },
                { id: "6", url: PAR6 },
                { id: "7", url: PAR7 },
                { id: "8", url: PAR8 },
                { id: "9", url: PAR9 },
                { id: "10", url: PAR10 },
                { id: "11", url: PAR11 },
                { id: "12", url: PAR12 },
                { id: "13", url: PAR13 },
                { id: "14", url: PAR14 },
                { id: "15", url: PAR15 },
                { id: "16", url: PAR16 },
                { id: "17", url: PAR17 },
                { id: "18", url: PAR18 },
                { id: "19", url: PAR19 },
                { id: "20", url: PAR20 },
                { id: "21", url: PAR21 }
            ]
        },
        {
            name: "PROYECTO BL",
            portada: PBL7,
            fotos: [
                { id: "1", url: PBL1 },
                { id: "2", url: PBL2 },
                { id: "3", url: PBL3 },
                { id: "4", url: PBL6 },
                { id: "5", url: PBL4 },
                { id: "6", url: PBL5 }
            ]
        },
        {
            name: "PROYECTO CC",
            portada: PCC1,
            fotos: [
                { id: "1", url: PCC2 },
                { id: "2", url: PCC4 },
                { id: "3", url: PCC3 },
                { id: "4", url: PCC5 },
                { id: "5", url: PCC6 },
                { id: "6", url: PCC7 },
                { id: "7", url: PCC8 }
            ]
        },
        {
            name: "PROYECTO CP",
            portada: PCP2,
            fotos: [
                { id: "1", url: PCP1 },
                { id: "2", url: PCP4 },
                { id: "3", url: PCP11 },
                { id: "4", url: PCP5 },
                { id: "5", url: PCP3 },
                { id: "6", url: PCP6 },
                { id: "7", url: PCP7 },
                { id: "8", url: PCP8 },
                { id: "9", url: PCP9 },
                { id: "10", url: PCP10 }
                
            ]
        },
        {
            name: "PROYECTO CR",
            portada: PCR1,
            fotos: [
                { id: "1", url: PCR1 },
                { id: "2", url: PCR2 },
                { id: "3", url: PCR3 },
                { id: "4", url: PCR4 },
                { id: "5", url: PCR5 },
                { id: "6", url: PCR6 }
            ]
        },
        {
            name: "PROYECTO GL",
            portada: PGL1,
            fotos: [
                { id: "1", url: PGL1 },
                { id: "2", url: PGL2 }, 
                { id: "3", url: PGL3 },
                { id: "4", url: PGL4 },
                { id: "5", url: PGL5 },
                { id: "6", url: PGL6 },
                { id: "7", url: PGL7 },
                { id: "8", url: PGL8 },
                { id: "9", url: PGL9 },
                { id: "10", url: PGL10 },
                { id: "11", url: PGL11 },
                { id: "12", url: PGL12 },
                { id: "13", url: PGL13 },
                { id: "14", url: PGL14 },
                { id: "15", url: PGL15 },
                { id: "16", url: PGL16 },
                { id: "17", url: PGL17 },
                { id: "18", url: PGL18 },
                { id: "19", url: PGL19 },
                { id: "20", url: PGL20 },
                { id: "21", url: PGL21 },
                { id: "22", url: PGL22 },
                { id: "23", url: PGL23 },
                { id: "24", url: PGL24 },
                { id: "25", url: PGL25 },
                { id: "26", url: PGL26 },
                { id: "27", url: PGL27 },
                { id: "28", url: PGL28 }    
            ]
        },
        {
            name: "PROYECTO GW",
            portada: PGR1,
            fotos: [
                { id: "1", url: PGR1 },
                { id: "2", url: PGR2 },
                { id: "3", url: PGR3 },
                { id: "4", url: PGR4 },
                { id: "5", url: PGR5 },
                { id: "6", url: PGR6 },
                { id: "7", url: PGR7 },
                { id: "8", url: PGR8 },
                { id: "9", url: PGR9 },
                { id: "10", url: PGR10 },
                { id: "11", url: PGR11 },
                { id: "12", url: PGR12 }
            ]
        },
        {
            name: "PROYECTO JZ",
            portada: PJZ1,
            fotos: [
                { id: "1", url: PJZ1 },
                { id: "2", url: PJZ2 },
                { id: "3", url: PJZ3 },
                { id: "4", url: PJZ4 },
                { id: "5", url: PJZ5 }
            ]
        },
        {
            name: "PROYECTO LB",
            portada: PLB1,
            fotos: [
                { id: "1", url: PLB1 },
                { id: "2", url: PLB2 },
                { id: "3", url: PLB3 },
                { id: "4", url: PLB4 },
                { id: "5", url: PLB5 },
                { id: "6", url: PLB6 },
                { id: "7", url: PLB7 },
                { id: "8", url: PLB8 },
                { id: "9", url: PLB9 },
                { id: "10", url: PLB10 },
                { id: "11", url: PLB11 },
            ]
        },
        {
            name: "PROYECTO LC",
            portada: PLC1,
            fotos: [
                { id: "1", url: PLC1 },
                { id: "2", url: PLC2 },
                { id: "3", url: PLC3 },
                { id: "4", url: PLC4 },
                { id: "5", url: PLC5 }
            ]
        },
        {
            name: "PROYECTO LD",
            portada: PLD11,
            fotos: [
                { id: "1", url: PLD1 },
                { id: "2", url: PLD2 },
                { id: "3", url: PLD3 },
                { id: "4", url: PLD4 },
                { id: "5", url: PLD5 },
                { id: "6", url: PLD6 },
                { id: "7", url: PLD7 },
                { id: "8", url: PLD8 },
                { id: "9", url: PLD9 },
                { id: "10", url: PLD10 },
                { id: "11", url: PLD11 },
                { id: "12", url: PLD12 },
            ]
        },
        {
            name: "PROYECTO MD",
            portada: PMD1,
            fotos: [
                { id: "1", url: PMD1 },
                { id: "2", url: PMD2 },
                { id: "3", url: PMD3 },
                { id: "4", url: PMD4 },
                { id: "5", url: PMD5 },
                { id: "6", url: PMD6 },
                { id: "7", url: PMD7 },
            ]
        },
        {
            name: "PROYECTO MG",
            portada: PMG6,
            fotos: [
                { id: "1", url: PMG1 },
                { id: "2", url: PMG2 },
                { id: "3", url: PMG3 },
                { id: "4", url: PMG4 },
                { id: "5", url: PMG5 },
                { id: "6", url: PMG6 },
            ]
        },
       
        {
            name: "PROYECTO RB",
            portada: PRB1,
            fotos: [
                { id: "1", url: PRB1 },
                { id: "2", url: PRB2 },
                { id: "3", url: PRB3 },
                { id: "4", url: PRB4 },
                { id: "5", url: PRB5 },
                { id: "6", url: PRB6 },
                { id: "7", url: PRB7 },
                { id: "8", url: PRB8 },
                { id: "9", url: PRB9 },
                { id: "10", url: PRB10 },
                { id: "11", url: PRB11 },
                { id: "12", url: PRB12 },
                { id: "13", url: PRB13 },
                { id: "14", url: PRB14 },
                { id: "15", url: PRB15 },
                { id: "16", url: PRB16 },
                { id: "17", url: PRB17 },
                { id: "18", url: PRB18 },
                { id: "19", url: PRB19 },
                { id: "20", url: PRB20 },
                { id: "21", url: PRB21 }
            ]
        },
        {
            name: "Proyecto RG",
            portada: PRG1,
            fotos: [
                { id: "1", url: PRG1 },
                { id: "2", url: PRG2 },
                { id: "3", url: PRG3 },
                { id: "4", url: PRG4 },
                { id: "5", url: PRG5 },
                { id: "6", url: PRG6 },
                { id: "7", url: PRG7 },
                { id: "8", url: PRG8 },
                { id: "9", url: PRG9 },
                { id: "10", url: PRG10 },
                { id: "11", url: PRG11 },
                { id: "12", url: PRG12 },
                { id: "13", url: PRG13 },
                { id: "14", url: PRG14 },
                { id: "15", url: PRG15 },
                { id: "16", url: PRG16 },
                { id: "17", url: PRG17 }
            ]
        },
        {
            name: "Proyecto RM",
            portada: PRM1,
            fotos: [
                { id: "1", url: PRM1 },
                { id: "2", url: PRM2 },
                { id: "3", url: PRM3 },
                { id: "4", url: PRM4 },
                { id: "5", url: PRM5 },
                { id: "6", url: PRM6 }
            ]
        },
        {
            name: "PROYECTO RS",
            portada: PRS1,
            fotos: [
                { id: "1", url: PRS1 },
                { id: "2", url: PRS2 },
                { id: "3", url: PRS3 },
                { id: "4", url: PRS4 },
                { id: "5", url: PRS5 },
                { id: "6", url: PRS6 },
                { id: "7", url: PRS7},
                { id: "8", url: PRS8 },
                { id: "9", url: PRS9 },
                { id: "10", url: PRS10 },
            ]
        },
        {
            name: "PROYECTO SB",
            portada: PSB1,
            fotos: [
                { id: "1", url: PSB1 },
                { id: "2", url: PSB2 },
                { id: "3", url: PSB3 },
                { id: "4", url: PSB4 },
                { id: "5", url: PSB5 },
                { id: "6", url: PSB6 },
                { id: "7", url: PSB7 },
            ]
        },
        {
            name: "PROYECTO TM",
            portada: PTM1,
            fotos: [
                { id: "1", url: PTM1 },
                { id: "2", url: PTM2 },
                { id: "3", url: PTM3 },
                { id: "4", url: PTM4 },
                { id: "5", url: PTM5 },
                { id: "6", url: PTM6 },
                { id: "7", url: PTM7 },
                { id: "8", url: PTM8 },
                { id: "9", url: PTM9 },
            ]
        },
        {
            name:"PROYECTO VS",
            portada: PVS1,
            fotos: [
                {id: "1", url: PVS1 },
                {id: "2", url: PVS2 },
                {id: "3", url: PVS3 },
                {id: "4", url: PVS4 },
                {id: "5", url: PVS5 },
                {id: "6", url: PVS6 },
                {id: "7", url: PVS7 },
                {id: "8", url: PVS8 },
                {id: "9", url: PVS9 },
            ]
        },
        {
            name: "Proyecto AC",
            portada: PAC1,
            fotos: [
                { id: "1", url: PAC2 },
                { id: "2", url: PAC3 },
                { id: "3", url: PAC4 },
                { id: "4", url: PAC5 },
                { id: "5", url: PAC6 }
            ]
        },
        {
            name: "PROYECTOS CR",
            portada: PSCR9,
            fotos: [
                { id: "1", url: PSCR1 },
                { id: "2", url: PSCR2 },
                { id: "3", url: PSCR3 },
                { id: "4", url: PSCR4 },
                { id: "5", url: PSCR5 },
                { id: "6", url: PSCR6 },
                { id: "7", url: PSCR7 },
                { id: "8", url: PSCR8 },
                { id: "9", url: PSCR9 },
                { id: "10", url: PSCR10 },
                { id: "11", url: PSCR11 },
                { id: "12", url: PSCR12 },
                { id: "13", url: PSCR13 },
                { id: "14", url: PSCR14 },
                { id: "15", url: PSCR15 },
                { id: "16", url: PSCR16 },
                { id: "17", url: PSCR17 },
                { id: "18", url: PSCR18 },
                { id: "19", url: PSCR19 }
            ]
        }

    ];
    
    return data;
}