import React, { useState } from 'react'
import { FOOTERC } from '../footer/footer';
import { NAVBAR } from '../navbar/navbar';
// import { ORDER_A } from './order1/ordera';
import { ORDER_B } from './order2/orderb';
import "./projects.css";
import ViewQuiltIcon from '@mui/icons-material/ViewQuilt';
import ReorderIcon from '@mui/icons-material/Reorder';
import { ORDER_A } from './order1/ordera';
export const PROYECTOS = () => {
  
  const [r, setr]=useState(true);
  const [vista, setvista]=useState(true);
  const [list, setlist]=useState(false);
  const [select, setselect]=useState(false);

  const [ingles, setingles]=useState(
    localStorage.getItem("ingles_curiel_web") === null
      ? false
      : localStorage.getItem("ingles_curiel_web") === "true"
      ? true
      : false
  );

  return (
    <>
    <NAVBAR op={3} ingles={ingles} setingles={setingles}></NAVBAR>
    <div className='Container-proyect'>
        <div className='Filtro'>
            <div className='vista'>
              <ViewQuiltIcon 
                onClick={()=>{
                  setvista(true);
                  setlist(false);
                }
              }></ViewQuiltIcon>
              <ReorderIcon 
                onClick={()=>{
                  setvista(false);
                  setlist(true);
                }
              }></ReorderIcon>
            </div>
        </div>
        {/* <ORDER_B filtro={r} ingles={ingles}></ORDER_B> */}
        <ORDER_A filtro={r} ingles={ingles}></ORDER_A>
        {/* {
            vista===true?
              <ORDER_A filtro={r}></ORDER_A>:
                list===true?
                  <ORDER_B filtro={r}></ORDER_B>:
                  <></>
        }  */}
    </div>
    <FOOTERC ingles={ingles} setingles={setingles}></FOOTERC>
    </>
    
  )
}
