import React, { useEffect, useState } from 'react'
import { FOOTERC } from '../../footer/footer';
import { NAVBAR } from '../../navbar/navbar';
import "./viewproject.css";
import { data_images_gallery } from './gallery';
import { VIEW_IMAGE } from './viewimage';
import { CustomDialog } from 'react-st-modal';

export const VIEW_PROJECT = () => {
  
  const [data, setdata]=useState([]);
  const [id, setid]=useState(localStorage.getItem("viewprojectcuriel"));

  const [ingles, setingles]=useState(
    localStorage.getItem("ingles_curiel_web") === null
      ? false
      : localStorage.getItem("ingles_curiel_web") === "true"
      ? true
      : false
  );

  const modal = (i)=>{

    console.log(data);
    console.log(i);

    
    CustomDialog(               
       <div>
          <VIEW_IMAGE data={data?.fotos} select={i}></VIEW_IMAGE>
       </div>,
     {
       className: "modalimage",
       title: "Galería",
       showCloseIcon: true,
       isCanClose:false,
       style:{
        color: "white"
       }
     })
  }


  useEffect(() => {
    const dat = data_images_gallery();
    const d = dat?.filter(item=>item?.name === id);
    console.log(d);
    setdata(d[0]);
  }, [])
  
  return (
    <>
      <NAVBAR ingles={ingles} setingles={setingles}></NAVBAR>
      <div className='container_view_project'>
        <div className='slider-view-project'>
           <img src={data?.portada}></img>
        </div>
        <div className='gallery_project'>
          <div className='text-gll'>
          
          </div>
          {
            data?.fotos?.reduce((pairs, item, index) => {
              if (index % 2 === 0) {
                pairs.push([item]);
              } else {
                pairs[pairs.length - 1].push(item);
              }
              return pairs;
            }, []).map((pair, pairIndex) => (
              <div className='grid-gallery' key={pairIndex}>
                {pair.map((item, index) => (
                  <div className={`gridgall gridgall${pairIndex * 2 + index + 1}`} key={index} 
                    style={{backgroundImage: `url(${item?.url})`, backgroundSize: "cover"}}
                    onClick={()=>modal(item?.id)}
                  >
                    {/* <img src={item?.url} alt={`Image ${pairIndex * 2 + index + 1}`} /> */}
                  </div>
                ))}
              </div>
            ))
          }
         
          
        </div>
      </div>
      <FOOTERC ingles={ingles}></FOOTERC>
    </>
  )
}
