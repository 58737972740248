import React, { useState } from 'react'
import "./viewimage.css";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

export const VIEW_IMAGE = (props) => {


    console.log(props);

    const [actual, setactual]=useState(props?.select-1);    
    const nextimage = () => {
        setactual((prevIndex) => (prevIndex + 1) % props.data.length);
      };
    
      const previusimage = () => {
        setactual((prevIndex) => (prevIndex - 1 + props.data.length) % props.data.length);
      };
    

  return (
    <div className='modal-view-image'>
        <div className='left'>
            <ArrowBackIosIcon onClick={()=>previusimage()} className='button-left'></ArrowBackIosIcon>
        </div>
        <img 
            src={props.data[actual]?.url} alt={`Imagen ${actual + 1}`}     
        ></img>
        <div className='rigth'>
            <ArrowForwardIosIcon onClick={()=>nextimage()}></ArrowForwardIosIcon>
        </div>
        
    </div>
  )
}
