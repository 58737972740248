import React, { useState } from 'react';
import "./nosotros.css";
import { NAVBAR } from '../navbar/navbar';
import { FOOTERC } from '../footer/footer';
import HeroSlider, { Slide, Nav, AutoplayButton } from "hero-slider";

import nos1 from "../../images/Nosotros/1.png";
import nos2 from "../../images/Nosotros/2.png";
import nos3 from "../../images/Nosotros/3.png";
import nos4 from "../../images/Nosotros/4.png";
import nos5 from "../../images/Nosotros/5.png";
import nos6 from "../../images/Nosotros/6.png";



export const NOSOTROS = () => {

    const [loaded, setLoaded] = useState(0);
      const [ingles, setingles]=useState(
        localStorage.getItem("ingles_curiel_web") === null
          ? false
          : localStorage.getItem("ingles_curiel_web") === "true"
          ? true
          : false
      );
    

  return (
    <>
    <div className='cuarzo'>
    <NAVBAR op={1} ingles={ingles} setingles={setingles}></NAVBAR>
    <div className='container-about' id='container-about'>
        <div className='about-info'>
            <div className='grid-about'>
                <div className='about-p'>
                    {
                        ingles===true?
                        <>
                        <p className='text-mini'>Curiel Arquitectos</p>
                        <h2 className='h2pp'>About Us</h2>
                        <p className='pp1'>
                            We are an architecture and desing company, always seeking to create innovative solutions that 
                            satisfy our curiosity to create and meet our client's economic and design needs.
                        </p>
                        <p className='pp2'>
                            Our fresh and positive vision of the future has and is creating a creative and innovative company, 
                            always demanding avant-garde ideas and better and new ways to plan, study, and execute them.
                            <br/><br/>
                            We can proudly say that the correct execution and purposeful design have earned us the trust of our 
                            clients inside and outside of Mexico
                        </p>
                        </>:
                        <>
                        <p className='text-mini'>Curiel Arquitectos</p>
                        <h2 className='h2pp'>Sobre Nosotros</h2>
                        <p className='pp1'>
                            Somos una empresa de arquitectura y diseño, buscamos siempre crear soluciones innovadoras que
                            satisfagan nuestra curiosidad por crear y las necesidades nuestros clientes tanto económicas como
                            de diseño.
                        </p>
                        <p className='pp2'>
                            Nuestra visión fresca y positiva del futuro han y están creando una empresa creativa e innovadora, siempre exigiéndonos ideas vanguardistas y mejores y nuevas formas de, planear, estudiar y ejecutar las mismas.
                            <br/>
                            <br/>
                            Con orgullo podemos decir que la correcta ejecución y diseño propositivo nos han hecho ganar la
                            confianza de nuestros clientes dentro y fuera de México.
                        </p>
                        </>
                    }
                </div>
            </div>
            <div className='carrusel-nosotros'>
              <HeroSlider
                  autoplay
                  height="70vh"
                  
                  controller={{
                    initialSlide: 1,
                    slidingDuration: 1000,
                    slidingDelay: 0,
                    onSliding: (nextSlide) =>
                      console.debug("onSliding(nextSlide): ", nextSlide, setLoaded(0)),
                    onBeforeSliding: (previousSlide, nextSlide) =>
                      console.debug(
                        "onBeforeSliding(previousSlide, nextSlide): ",
                        previousSlide,
                        nextSlide,
                        setLoaded(0)
                      ),
                    onAfterSliding: (nextSlide) =>
                      console.debug("onAfterSliding(nextSlide): ", nextSlide, setLoaded(0))
                  }}
              >
              <Slide
                background={{
                  maskBackgroundBlendMode: 'luminosity',
                  backgroundImageSrc: nos1,
                  backgroundAnimation: 'fade'     
                }}
              ></Slide>
              <Slide
                background={{
                  maskBackgroundBlendMode: 'luminosity',
                  backgroundImageSrc: nos2,
                  backgroundAnimation: 'fade'     
                }}
              ></Slide>
              <Slide
                background={{
                  maskBackgroundBlendMode: 'luminosity',
                  backgroundImageSrc: nos3,
                  backgroundAnimation: 'fade'     
                }}
              ></Slide>
              <Slide
                background={{
                  maskBackgroundBlendMode: 'luminosity',
                  backgroundImageSrc: nos4,
                  backgroundAnimation: 'fade'     
                }}
              ></Slide>
              <Slide
                background={{
                  maskBackgroundBlendMode: 'luminosity',
                  backgroundImageSrc: nos5,
                  backgroundAnimation: 'fade'     
                }}
              ></Slide>
              <Slide
                background={{
                  maskBackgroundBlendMode: 'luminosity',
                  backgroundImageSrc: nos6,
                  backgroundAnimation: 'fade'     
                }}
              ></Slide>
            <Nav></Nav>
            </HeroSlider>  
              </div>
            <div className='filos' id="filos">
                <div className='capa-f'></div>
                {
                    ingles===true?
                    <p className='frase'>
                        Love and enjoy what you do, and let that be reflected in your projects, from the biggest image to the smallest detail.
                    </p>:
                    <p className='frase'>
                        "Ama y disfruta lo que haces y deja que eso se refleje en tus proyectos, desde la imagen más grande hasta el detalle más pequeño."
                    </p>
                }  
            </div>
        </div>
    </div>
    <FOOTERC ingles={ingles}></FOOTERC>
    </div>
    </>
    
  )
}
